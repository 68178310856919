import { overrideTailwindClasses } from 'tailwind-override';
import invariant from 'tiny-invariant';

export function clsx(...args: any[]): string {
	const entries = args.reduce(
		(acc: Record<string, boolean>, a) => {
			if (a) {
				if (typeof a === 'object') {
					if (Array.isArray(a)) {
						acc[clsx(...a)] = true;
						return acc;
					} else {
						Object.assign(acc, a);
						return acc;
					}
				} else if (typeof a === 'string') {
					acc[a] = true;
					return acc;
				}
			}

			return acc;
		},
		{} as Record<string, boolean>,
	);

	return overrideTailwindClasses(
		Object.entries(entries)
			.filter(([, value]) => value)
			.map(([key]) => key)
			.join(' '),
	);
}

// https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
export const convertHexToHsl = (hex: string) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	invariant(result && result.length > 3, `Failed to convert hex value: "${hex}"`);
	let r = Number.parseInt(result[1], 16);
	let g = Number.parseInt(result[2], 16);
	let b = Number.parseInt(result[3], 16);
	r /= 255;
	g /= 255;
	b /= 255;
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);
	let h = (max + min) / 2;
	let s = (max + min) / 2;
	let l = (max + min) / 2;
	if (max === min) {
		h = s = 0; // achromatic
	} else {
		const d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}

	s = s * 100;
	s = Math.round(s);
	l = l * 100;
	l = Math.round(l);
	h = Math.round(360 * h);
	return { h, s, l };
};

const SIZES = ['xs', 'sm', 'md', 'lg'] as const;
export type Size = (typeof SIZES)[number];

const isSize = (s: unknown): s is Size => {
	return SIZES.includes(s as Size);
};

export const buttonSizeClass = (size?: Size) => {
	return clsx({
		'btn-xs': size === 'xs',
		'btn-sm': size === 'sm',
		'btn-md': size === 'md',
		'btn-lg': size === 'lg',
	});
};

export const inputSizeClass = (size?: Size) => {
	return clsx({
		'input-xs': size === 'xs',
		'input-sm': size === 'sm',
		'input-md': size === 'md',
		'input-lg': size === 'lg',
	});
};

export const radioSizeClass = (size?: Size) => {
	return clsx({
		'radio-xs': size === 'xs',
		'radio-sm': size === 'sm',
		'radio-md': size === 'md',
		'radio-lg': size === 'lg',
	});
};

export const sizeFromElementClass = (
	element: 'btn' | 'input' | 'radio',
	className: string,
): Size | undefined => {
	const pattern = new RegExp(`${element}-(xs|sm|md|lg)`, 'g');

	let match;
	let lastMatch;

	while ((match = pattern.exec(className)) !== null) {
		const size = match[1];
		if (isSize(size)) {
			lastMatch = size;
		}
	}

	return lastMatch;
};
